<template lang="">
<div>
    <v-container class="pa-2 d-flex justify-center">
        <v-col lg="4" sm="6" style="padding: 0px;">
            <v-row class="d-flex justify-center align-center">
                <v-img src="@/assets/img/checkmong/simple_azq/logo-Eisai_03.png" contain max-height="100px" max-width="150px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/02-Eisai-logo1.png" contain max-height="50px" max-width="70px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/logo3.png" contain max-height="50px" max-width="70px"></v-img>
            </v-row>
            <v-container class="">
                <br>
                <v-row class="d-flex justify-center" style="padding-left: 66px;">
                    <v-img src="@/assets/img/checkmong/simple_azq/IconStart.png" contain max-height="250px"></v-img>
                </v-row>
                <br>
                <v-row class="d-flex justify-center" style="text-align: center">
                    <h2 class="text-pink">แบบคำถามประเมินความเสี่ยงสมองเสื่อม</h2>
                    <br>
                    <span class="text-size">
                        การประเมินภาวะสมองเสื่อม เป็นการค้นหาผู้มีแนวโน้ม
                        หรือความเสี่ยงที่จะมีภาวะสมองเสื่อมเพื่อป้องกัน
                        และเข้ารับการบำบัดรักษาโดยแพทย์ผู้เชี่ยวชาญ
                    </span>
                </v-row>
                <br>
                <br>
                <br>
                <v-row class="d-flex justify-center">
                    <v-btn elevation="2" x-large block rounded class="btn-pink" @click="goReg">
                        <h2 style="font-weight: bold; letter-spacing: -1px; ">เริ่มทำแบบทดสอบ</h2>
                    </v-btn>
                </v-row>

            </v-container>

        </v-col>
    </v-container>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    data() {
        return {
            logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            name: '',
            age: null,
            phone: '',
            valid: true,
            is_download_btn_active: false,
        };
    },
    watch: {

    },

    methods: {
        goReg() {
            this.$router.push('/simple_azq_reg')
        }
    },
    beforeCreate() {

    },
    mounted() {

    },
    created() {},
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

<style scoped>
.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-pink {
    background: #AE1B77 !important;
    color: white !important;
    box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
    font-weight: bold !important;
}

.text-pink {
    color: #601550 !important;
}

.text-size {
    font-size: 18px;
}
</style>
